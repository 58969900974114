import React from "react";
import { Global, css } from "@emotion/core";

import { TEXT_COLOR, theme } from ".";

const GlobalStyles = () => (
    <Global
        styles={css`
            body {
                margin: 0;
                font-family: "Roboto", -apple-system, BlinkMacSystemFont,
                    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
                    "Droid Sans", "Helvetica Neue", sans-serif;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                color: ${TEXT_COLOR};
            }

            * {
                font-family: "Roboto", -apple-system, BlinkMacSystemFont,
                    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
                    "Droid Sans", "Helvetica Neue", sans-serif;
            }

            p {
                padding: 0;
                margin: ${theme.space[2]}px 0;
            }

            *:focus {
                transition: box-shadow 0.2s ease-in-out;
                box-shadow: 0 0 6px 1px ${theme.colors.primary};
                outline: none;
            }
        `}
    />
);

export default GlobalStyles;
