/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { FC, useMemo, useState } from "react";
import { Box, Flex } from "reflexbox";
import backgroundImage from "../assets/background.jpg";
import illustration from "../assets/illustration.png";
import { useAppState } from "../state";
import { useLocalization } from "../state/Localization";
import { theme, Theme } from "../theme";
import GlobalStyles from "../theme/GlobalStyles";
import Calculator from "./Calculator";
import Result from "./Results";
import StyledFlex from "./styled/StyledFlex";
import Typography from "./styled/Typography";
import { ReactComponent as QuestionCircle } from "../assets/question-circle-regular.svg";
import { UnstyledButton } from "./styled/Button";
import useClickaway from "../useClickaway";

const Home: FC = () => {
    const getString = useLocalization();

    const [
        {
            isUnset,
            complexity,
            experiencedManagers,
            competition,
            salary,
            currency,
            managers,
        },
    ] = useAppState();

    const fixedValues = [
        [13, getString("results.first")],
        [24131, getString("results.second", { currency: getString("CZK") })],
        [
            868716,
            getString("results.third.singular", {
                currency: getString("CZK"),
                managers: 3,
            }),
        ],
    ] as const;

    const results = [
        [
            useMemo(
                () =>
                    isUnset
                        ? undefined
                        : 14.781 +
                          complexity * 3.302 -
                          (experiencedManagers / managers) * 5.807 -
                          competition * 1.766,
                [
                    isUnset,
                    complexity,
                    experiencedManagers,
                    competition,
                    managers,
                ],
            ),
            getString("results.first"),
        ],
        [
            useMemo(
                () =>
                    isUnset
                        ? undefined
                        : ((14.781 +
                              complexity * 3.302 -
                              (experiencedManagers / managers) * 5.807 -
                              competition * 1.766) /
                              40.0) *
                          salary *
                          1.34,
                [
                    isUnset,
                    complexity,
                    experiencedManagers,
                    competition,
                    salary,
                    managers,
                ],
            ),
            getString("results.second", { currency: getString(currency) }),
        ],
        [
            useMemo(
                () =>
                    isUnset
                        ? undefined
                        : ((14.781 +
                              complexity * 3.302 -
                              (experiencedManagers / managers) * 5.807 -
                              competition * 1.766) /
                              40.0) *
                          salary *
                          1.34 *
                          12 *
                          managers,
                [
                    isUnset,
                    complexity,
                    experiencedManagers,
                    competition,
                    salary,
                    managers,
                ],
            ),
            getString(
                managers > 1
                    ? "results.third.plural"
                    : "results.third.singular",
                { currency: getString(currency), managers },
            ),
        ],
    ] as const;

    const [isSourceTooltipOpen, setSourceTooltipOpen] = useState(false);
    const [tooltipClickaway] = useClickaway<HTMLDivElement>(
        () => isSourceTooltipOpen && setSourceTooltipOpen(false),
    );

    return (
        <Flex
            flexDirection="column"
            css={css`
                overflow-x: hidden;
            `}
        >
            <GlobalStyles />
            <Flex
                backgroundColor="bgDark"
                css={css`
                    position: relative;
                `}
            >
                <div
                    css={(theme: Theme) => css`
                        position: absolute;
                        top: 0;
                        left: 40%;
                        height: 100%;
                        width: 60%;
                        min-width: 600px;
                        z-index: 0;
                        overflow: hidden;

                        & > img {
                            min-height: 100%;
                            width: 100%;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 100%;
                            height: 100%;
                            display: inline-block;
                            background-image: linear-gradient(
                                90deg,
                                ${theme.colors.bgDark} 0%,
                                transparent 50%
                            );
                        }
                    `}
                >
                    <img src={backgroundImage} alt="" />
                </div>
                <StyledFlex
                    flexDirection="column"
                    bodyWidth
                    py={4}
                    flexGrow={1}
                    css={css`
                        z-index: 0;
                    `}
                >
                    <Typography
                        {...{ as: "h2" }}
                        textColor="white"
                        textStyle="heading1"
                        my={3}
                    >
                        {getString("home.title")}
                    </Typography>
                    <Typography
                        textColor="white"
                        mt={0}
                        mb={4}
                        css={css`
                            max-width: 400px;
                        `}
                    >
                        {getString("home.subtitle")}
                    </Typography>
                    <StyledFlex
                        rounded
                        alignSelf="flex-start"
                        backgroundColor="white"
                    >
                        <CarouselProvider
                            naturalSlideWidth={270}
                            naturalSlideHeight={150}
                            totalSlides={3}
                            infinite
                            isPlaying
                            interval={4000}
                            touchEnabled={false}
                            dragEnabled={false}
                            css={css`
                                position: relative;
                            `}
                        >
                            {/* Source tooltip */}
                            <Box
                                ref={tooltipClickaway}
                                size={20}
                                css={(theme: Theme) => css`
                                    z-index: 999;
                                    position: absolute;
                                    bottom: ${theme.space[2]}px;
                                    right: ${theme.space[2]}px;
                                    line-height: 0;
                                `}
                            >
                                <Box
                                    as={UnstyledButton}
                                    size={20}
                                    onClick={() =>
                                        setSourceTooltipOpen(o => !o)
                                    }
                                    color="textGrey"
                                    css={css`
                                        line-height: 0;
                                        cursor: pointer;
                                        border-radius: 50%;
                                    `}
                                >
                                    <QuestionCircle />
                                </Box>
                                <Box
                                    css={css`
                                        position: relative;
                                    `}
                                >
                                    {isSourceTooltipOpen && (
                                        <StyledFlex
                                            shadow
                                            rounded
                                            px={3}
                                            py={2}
                                            bg="white"
                                            css={(theme: Theme) => css`
                                                position: absolute;
                                                top: -28px;
                                                transform: translateY(-100%);
                                                width: 200px;
                                                line-height: initial;

                                                @media (max-width: ${theme
                                            .breakpoints[0]}) {
                                                    right: 0;
                                                }
                                            `}
                                        >
                                            <Typography
                                                textStyle="small"
                                                textColor="textGrey"
                                                textAlign="center"
                                                my={0}
                                            >
                                                {getString(
                                                    "results.tooltip.start",
                                                )}
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={getString(
                                                        "data.link",
                                                    )}
                                                >
                                                    {getString(
                                                        "results.tooltip.research",
                                                    )}
                                                </a>
                                                {getString(
                                                    "results.tooltip.end",
                                                )}
                                            </Typography>
                                        </StyledFlex>
                                    )}
                                </Box>
                            </Box>

                            <Slider
                                css={css`
                                    width: 410px;
                                    height: 100px;
                                    @media (min-width: ${theme
            .breakpoints[0]}) {
                                        width: 270px;
                                        height: 150px;
                                    }
                                `}
                            >
                                {[0, 1, 2].map(i => (
                                    <Slide index={i} key={i}>
                                        <Result
                                            value={fixedValues[i][0]}
                                            notAnimated
                                        >
                                            {fixedValues[i][1]}
                                        </Result>
                                    </Slide>
                                ))}
                            </Slider>
                        </CarouselProvider>
                    </StyledFlex>
                </StyledFlex>
            </Flex>
            <Flex
                px={[theme.bodyPadding[0], 0]}
                py={4}
                flexDirection="column"
                backgroundColor="bg"
            >
                <Typography textAlign="center" textStyle="heading2">
                    {getString("calc.title")}
                </Typography>
            </Flex>
            <Flex
                justifyContent="center"
                css={css`
                    position: relative;
                `}
            >
                <Box
                    backgroundColor="bg"
                    css={css`
                        position: absolute;
                        height: 50%;
                        width: 100%;
                        z-index: -1;
                    `}
                />
                <Calculator />
            </Flex>
            <Flex flexDirection="column">
                <StyledFlex bodyWidth flexDirection="column">
                    <Typography
                        textAlign="center"
                        textStyle="heading2"
                        pt={4}
                        px={[theme.bodyPadding[0], 0]}
                    >
                        {getString("results.title")}
                    </Typography>
                    <Typography
                        textStyle="small"
                        fontStyle="italic"
                        textColor="textGrey"
                        textAlign="center"
                        my={0}
                    >
                        {getString("results.subtitle.start")}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getString("data.link")}
                        >
                            {getString("results.subtitle.research")}
                        </a>
                        {getString("results.subtitle.end")}
                    </Typography>
                    <Flex
                        py={4}
                        justifyContent="space-around"
                        alignItems="center"
                        flexDirection={["column", "row"]}
                    >
                        <Result value={results[0][0]} flexBasis={"33%"}>
                            {results[0][1]}
                        </Result>
                        <Result
                            value={results[1][0]}
                            flexBasis={"33%"}
                            css={(theme: Theme) => css`
                                border: 1px solid ${theme.colors.grey};
                                @media (min-width: ${theme.breakpoints[0]}) {
                                    border-top: 0;
                                    border-bottom: 0;
                                }
                                @media (max-width: ${theme.breakpoints[0]}) {
                                    border-left: 0;
                                    border-right: 0;
                                }
                            `}
                        >
                            {results[1][1]}
                        </Result>
                        <Result value={results[2][0]} flexBasis={"33%"}>
                            {results[2][1]}
                        </Result>
                    </Flex>
                    <Typography mb={3}>{getString("results.text1")}</Typography>
                    <Flex flexDirection={["column", "row"]}>
                        <Flex
                            as="ul"
                            flexDirection="column"
                            flexBasis={["100%", "50%"]}
                        >
                            <Typography {...{ as: "li" }}>
                                {getString("results.point1")}
                            </Typography>
                            <Typography {...{ as: "li" }}>
                                {getString("results.point2")}
                            </Typography>
                        </Flex>
                        <Flex
                            as="ul"
                            flexDirection="column"
                            flexBasis={["100%", "50%"]}
                        >
                            <Typography {...{ as: "li" }}>
                                {getString("results.point3")}
                            </Typography>
                            <Typography {...{ as: "li" }}>
                                {getString("results.point4")}
                            </Typography>
                        </Flex>
                    </Flex>
                    <Typography mt={4}>{getString("results.text2")}</Typography>
                    <Typography>{getString("results.text3")}</Typography>
                    <hr
                        css={(theme: Theme) => css`
                            width: 100%;
                            margin-top: ${theme.space[4]}px;
                            margin-bottom: ${theme.space[4]}px;
                            border-color: ${theme.colors.bg};
                        `}
                    />
                    <Typography textAlign="center" textStyle="heading2">
                        {getString("what.title")}
                    </Typography>
                    <Typography>{getString("what.text1")}</Typography>
                    <Flex my={4} flexWrap="wrap">
                        <Flex
                            flexDirection="column"
                            flexBasis={["100%", "50%"]}
                            pr={[0, 3]}
                        >
                            <Typography textColor="primary" fontWeight="bold">
                                {getString("what.subtitle1")}
                            </Typography>
                            <Typography>{getString("what.text2")}</Typography>
                            <Box as="ul">
                                <Typography {...{ as: "li" }}>
                                    {getString("what.point1")}
                                </Typography>
                                <Typography {...{ as: "li" }}>
                                    {getString("what.point2")}
                                </Typography>
                                <Typography {...{ as: "li" }}>
                                    {getString("what.point3")}
                                </Typography>
                            </Box>
                            <Typography>{getString("what.text3")}</Typography>
                        </Flex>
                        <Flex
                            flexDirection="column"
                            flexBasis={["100%", "50%"]}
                            pl={[0, 3]}
                        >
                            <Typography textColor="primary" fontWeight="bold">
                                {getString("what.subtitle2")}
                            </Typography>
                            <Typography>{getString("what.text4")}</Typography>
                            <div
                                css={css`
                                    background-image: url(${illustration});
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    filter: grayscale(100%);
                                    flex-grow: 1;
                                    margin-top: 8px;
                                `}
                            />
                        </Flex>
                    </Flex>
                </StyledFlex>
                <Flex
                    justifyContent="center"
                    css={css`
                        position: relative;
                    `}
                >
                    <Box
                        backgroundColor="bg"
                        css={css`
                            position: absolute;
                            height: 50%;
                            width: 100%;
                            bottom: 0;
                            z-index: -1;
                        `}
                    />
                    <StyledFlex
                        fixedWidth
                        rounded
                        backgroundColor="light"
                        flexDirection="column"
                        px={[theme.bodyPadding[0], 5]}
                        py={4}
                    >
                        <Typography textStyle="heading2" textAlign="center">
                            {getString("more.title")}
                        </Typography>
                        <Typography textAlign="center">
                            {getString("more.text")}
                        </Typography>
                        <Typography textAlign="center" fontSize={24}>
                            {getString("more.contact")}
                            <a href="mailto:talpova@econ.muni.cz">
                                talpova@econ.muni.cz
                            </a>
                        </Typography>
                    </StyledFlex>
                </Flex>
                <Flex backgroundColor="bg" pt={4} pb={5}>
                    <StyledFlex bodyWidth flexDirection="column">
                        <Typography textStyle="heading2" textAlign="center">
                            {getString("data.title")}
                        </Typography>
                        <Typography>
                            {getString("data.text")}
                            <a href={getString("data.link")}>
                                {getString("data.here")}
                            </a>
                            .
                        </Typography>
                    </StyledFlex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Home;
