/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { Flex } from "reflexbox";
import { Theme } from "../theme";
import { UnstyledButton } from "./styled/Button";
import Typography from "./styled/Typography";

type Props = {
    id: string;
    value: number;
    onSetValue: (id: string, value: number) => void;
    onSetTouched: (id: string, value: boolean) => void;
    labels?: Partial<Record<1 | 2 | 3 | 4 | 5, string>>;
};

const RangeInput: React.FC<Props> = ({
    id,
    value,
    onSetValue,
    onSetTouched,
    labels = {},
}) => {
    return (
        <Flex
            id={id}
            justifyContent="space-between"
            mt={3}
            mb={4}
            css={(theme: Theme) =>
                css`
                    border-top: 4px solid ${theme.colors.light};
                    height: 0;
                `
            }
        >
            {[...Array(5).keys()]
                .map(i => i + 1)
                .map(i => (
                    <UnstyledButton
                        type="button"
                        key={i}
                        onClick={() => {
                            onSetValue(id, i);
                            onSetTouched(id, true);
                        }}
                        css={(theme: Theme) => css`
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            user-select: none;
                            cursor: pointer;

                            height: 20px;
                            width: 20px;
                            margin-top: -12px;

                            border-radius: 50%;
                            border: 1px solid #5f9afe;

                            background-color: ${theme.colors.light};

                            transition: all 0.2s ease-in-out;

                            ${value === i &&
                                css`
                                    border-width: 4px;
                                    background-color: white;
                                `}

                            ${value !== i &&
                                css`
                                    &:hover {
                                        border-width: 2px;
                                        background-color: white;
                                    }
                                `}
                        `}
                    >
                        <Typography
                            textStyle="small"
                            css={css`
                                transform: translateY(100%);
                                white-space: nowrap;
                            `}
                        >
                            {`${i} ${(labels as any)[i] ?? ""}`}
                        </Typography>
                    </UnstyledButton>
                ))}
        </Flex>
    );
};
export default RangeInput;
