import { createContext, useContext, useCallback } from "react";

type LanguageTypes = "EN" | "CZ";

const localizations = {
    "home.title": {
        EN: "Failure to share knowledge doesn't come cheap.",
        CZ: "Nesdílení znalostí je drahé.",
    },
    "home.subtitle": {
        EN:
            "Using our on-line calculator, find out in a minute how much lost knowledge of project managers has cost your company.",
        CZ:
            "Zjistěte během minuty, jaký náklad způsobují ztracené znalosti projektových manažerů ve vaší společnosti díky naší online kalkulačce.",
    },
    "calc.title": {
        EN: "How much do you lose due to inefficient knowledge transfer?",
        CZ: "Kolik toho ztrácíte na základě neefektivního předávaní znalostí?",
    },
    "calc.managers.label": {
        EN: "How many project managers work in your company?",
        CZ: "Kolik u vás pracuje projektových manažerů?",
    },
    "calc.managers.min": {
        EN: "Minimal required managers count is 1.",
        CZ: "Minimální počet manažerů je 1.",
    },
    "calc.experiencedManagers.label": {
        EN: "How many of them have completed four or more projects?",
        CZ: "Kolik z nich ukončilo 4 a více projektů?",
    },
    "calc.experiencedManagers.min": {
        EN: "Minimal required experienced managers count is 0.",
        CZ: "Minimální počet zkušených manažerů je 0.",
    },
    "calc.experiencedManagers.fewer": {
        EN: "There can be no more experienced managers then all managers.",
        CZ: "Zkušených manažerů nemůže byt víc než všech.",
    },
    "calc.complexity.label": {
        EN: "How complex is a typical project in terms of coordination?",
        CZ: "Jak složitý je typický projekt z hlediska koordinace?",
    },
    "calc.complexity.sublabel": {
        EN:
            "(number of entities, number of project team members, level of follow-up to processes in the company, number of contracts)",
        CZ:
            "(počet subjektů, počet členů projektového týmu, míra návaznosti na procesy ve společnosti, množství smluv)",
    },
    "calc.competition.label": {
        EN:
            "What is the level of competition in the environment in which your company operates?",
        CZ:
            "Jaká je míra konkurence v prostředí, ve kterém vaše společnost působí?",
    },
    "calc.competition.sublabel": {
        EN:
            "(how tough is the competitive struggle, the use of unfair tactics (corruption), etc.)",
        CZ:
            "(jak tvrdý je konkurenční boj, využívání neférových taktik (korupce) apod.)",
    },
    "calc.salary.label": {
        EN: "What is the average monthly gross salary of a project manager?",
        CZ: "Jaký je průměrný měsíční hrubý plat projektového manažera?",
    },
    "calc.salary.min": {
        EN: "Salary can't be negative.",
        CZ: "Plat nemůže být záporný.",
    },
    "calc.calculate": {
        EN: "Calculate",
        CZ: "Vypočítat",
    },
    "form.required": {
        EN: "Required",
        CZ: "Požadované",
    },
    "form.people": {
        EN: "people",
        CZ: "lidí",
    },
    "form.simple": {
        EN: "(simple)",
        CZ: "(snadný)",
    },
    "form.complex": {
        EN: "(complex)",
        CZ: "(složitý)",
    },
    "form.low": {
        EN: "(low)",
        CZ: "(nízká)",
    },
    "form.high": {
        EN: "(high)",
        CZ: "(vysoká)",
    },
    "results.title": {
        EN: "Results of the data entered",
        CZ: "Výsledek zadaných dat",
    },
    "results.tooltip.start": {
        EN: "Based on ",
        CZ: "Jedná se o průměrné hodnoty vycházející z ",
    },
    "results.tooltip.research": {
        EN: "research",
        CZ: "průzkumu",
    },
    "results.tooltip.end": {
        EN: " among project managers in the Czech Republic",
        CZ: " mezi projektovými manažery v ČR",
    },
    "results.subtitle.start": {
        EN: "(average time based on ",
        CZ: "(výpočet založen na výstupech ",
    },
    "results.subtitle.research": {
        EN: "research",
        CZ: "průzkumu",
    },
    "results.subtitle.end": {
        EN: " among project managers)",
        CZ: " mezi projektovými manažery v ČR)",
    },
    "results.first": {
        EN: "hours a week spent by a PM inefficiently",
        CZ: "hodin týdně stráví PM neefektivně",
    },
    "results.second": {
        EN: "%currency% per month is the price of inefficient time of 1 PM",
        CZ: "%currency% měsíčně stojí neefektivní čas jednoho PM",
    },
    "results.third.singular": {
        EN:
            "%currency% represents the annual costs in the organization with %managers% PM",
        CZ: "%currency% ročně jsou náklady v organizaci s %managers% PM",
    },
    "results.third.plural": {
        EN:
            "%currency% represents the annual costs in the organization with %managers% PM's",
        CZ: "%currency% ročně jsou náklady v organizaci se %managers% PM",
    },
    "results.text1": {
        EN:
            "This is the time or value of the time that project managers spend searching for information or passing it on, i.e.:",
        CZ:
            "Jde o čas nebo hodnotu času, který u vás projektoví manažeři tráví hledáním informací nebo jejich předáváním, tedy:",
    },
    "results.point1": {
        EN: "waiting for inputs from others,",
        CZ: "čekáním na vstupy od ostatních,",
    },
    "results.point2": {
        EN: "reworking of already finished work due to lack of information,",
        CZ: "předělávání již hotové práce kvůli nedostatku informací,",
    },
    "results.point3": {
        EN: "searching for information and documents,",
        CZ: "hledáním informací a dokumentů,",
    },
    "results.point4": {
        EN: "disseminating information to others.",
        CZ: "předávání informací ostatním.",
    },
    "results.text2": {
        EN:
            "This calculated cost is not directly visible. This often leads to companies and managers paying too little attention to the sharing of knowledge and experience. However, inefficiencies will be reflected in higher project costs and lower labour productivity.",
        CZ:
            "Výše vypočítaný náklad není přímo vidět. I díky tomuto se sdílení znalostí a zkušeností věnuje často malá pozornost. Neefektivity se ale projeví ve vyšších nákladech projektů a nižší produktivitě práce.",
    },
    "results.text3": {
        EN:
            "If the numbers seem too high, we can help you streamline your knowledge transfer. The activities above can never be completely eliminated, but in most cases it is possible to significantly reduce the amount of time that needs to be devoted to them. Often only a few small changes are enough and the transfer of knowledge can be improved.",
        CZ:
            "Pokud se vám čísla zdají moc vysoká, můžeme vám pomoci přenos znalostí zefektivnit. Výše uvedené činnosti nelze nikdy zcela eliminovat, většinou je ale možné výrazně snížit množství času, který je třeba jim věnovat. Mnohdy stačí jen pár drobných změn a přenos znalostí začne fungovat lépe.",
    },
    "what.title": {
        EN: "What to do about it?",
        CZ: "Jak na to?",
    },
    "what.text1": {
        EN:
            "We have many solutions. Here's a little taste of what's been most successful in practice.",
        CZ:
            "Možnosti řešení máme mnoho. Tady je malá ochutnávka z toho, co se nám v praxi nejčastěji osvědčilo.",
    },
    "what.subtitle1": {
        EN: "Lessons learned even during the project.",
        CZ: "Lessons learned i v průběhu projektu.",
    },
    "what.text2": {
        EN:
            "Most project management methodologies recommend evaluating the project at the end of it and recording experiences That makes sense. But it also loses a lot of experiences. Why?",
        CZ:
            "Většina metodik projektového řízení doporučuje zhodnotit projekt na jeho konci a zaznamenat zkušenosti. Dává to smysl. Ale také se tím velká část zkušeností ztratí. Proč?",
    },
    "what.point1": {
        EN:
            "after the end of the project, project managers will mostly recall only more recent events",
        CZ:
            "projektoví manažeři si po konci projektu vybaví většinou jen nedávné události",
    },
    "what.point2": {
        EN:
            "often only part of the problem or its solution is registered - and the experience cannot then be realistically used",
        CZ:
            "často se zaznamená jen část problému nebo jeho řešení - a zkušenosti tak nelze reálně využít",
    },
    "what.point3": {
        EN:
            "after the end of the project, project managers do not want to deal with anything.",
        CZ:
            "projektovým manažerům už se po skončení projektu nechce řešit opravdu nic.",
    },
    "what.text3": {
        EN:
            "The solution is to include short, concise and effective sharing of experiences already during the project.",
        CZ:
            "Řešením je zařadit krátké, stručné a efektivní sdílení zkušeností už v průběhu projektu.",
    },
    "what.subtitle2": {
        EN: "Involve a third party",
        CZ: "Zapojte někoho třetího",
    },
    "what.text4": {
        EN:
            "And in addition, you can try having lessons learned taken down by someone outside the project. The point is that if you can explain to someone else what happened to you in the project and how you solved the situation, this will greatly increase the transferability of the experience.",
        CZ:
            "A kromě toho můžete vyzkoušet, že zápis lessons learned bude dělat někdo, kdo není součástí daného projektu. Pokud totiž dokážete vysvětlit někomu třetímu, co se vám v projektu stalo a jak jste danou situaci vyřešili, značně tím zvýšíte přenositelnost zkušenosti.",
    },
    "more.title": {
        EN: "Would you like to find out more?",
        CZ: "Chcete vědět víc?",
    },
    "more.text": {
        EN:
            "We are preparing, developing and testing this and other solutions right now. We will then include this in software that we will develop and which will support the proposed processes. Do you want to get involved and be among the first to use the solutions designed? Or might you prefer a tailor-made solution?",
        CZ:
            "Toto a další řešení právě teď připravujeme, vyvíjíme a testujeme. Ta pak zahrneme do softwaru, který budeme vyvíjet a který bude podporovat navržené procesy. Chcete se zapojit a využít pak navržená řešení mezi prvními? Nebo chcete řešení na míru?",
    },
    "more.contact": {
        EN: "Contact us! ",
        CZ: "Ozvěte se nám! ",
    },
    "data.title": {
        EN: "Where did we get the data?",
        CZ: "Odkud máme data?",
    },
    "data.text": {
        EN:
            "The values above are based on data from our research. We surveyed more than 150 project managers in Czech and multinational companies. Over two-thirds of them have managed projects for over six years, both internally and for external customers. In the last three years, they have focused mainly on software development and service delivery. Are you interested in what tools they use for communication and sharing knowledge and information? More about our project can be found ",
        CZ:
            "Výše uvedené hodnoty vycházejí z dat z našeho výzkumu. Ptali jsme se více než 150 projektových manažerů v českých i nadnárodních společnostech. Přes dvě třetiny z nich řídí projekty více než 6 let a to jak interní, tak pro externí zákazníky. V posledních třech letech se zaměřovali převážně na vývoj softwaru a poskytování služeb. Zajímá Vás, jaké nástroje pro komunikaci a sdílení znalostí a informací používají? Více o našem projektu najdete ",
    },
    "data.here": {
        EN: "HERE",
        CZ: "ZDE",
    },
    "data.link": {
        EN: "https://pkm.econ.muni.cz/en/research-results",
        CZ: "https://pkm.econ.muni.cz/vysledky-vyzkumu",
    },
    CZK: {
        EN: "CZK",
        CZ: "Kč",
    },
    EUR: {
        EN: "EUR",
        CZ: "€",
    },
    USD: {
        EN: "USD",
        CZ: "$",
    },
} as const;

type LocalizationState = {
    language: LanguageTypes;
    localizations: typeof localizations;
};

const LocalizationContext = createContext<LocalizationState>({
    language: "EN",
    localizations,
});

export const useLocalizationProvider = (language: LanguageTypes) => {
    return {
        LocalizationProvider: LocalizationContext.Provider,
        localization: {
            language,
            localizations,
        },
    };
};

export const useLocalization = () => {
    const value = useContext(LocalizationContext);
    const getString = useCallback(
        (
            key: keyof typeof localizations,
            replacements?: { [key: string]: any },
        ) =>
            value.localizations[key][value.language].replace(
                /%\w+%/g,
                all => replacements?.[all.substr(1, all.length - 2)] ?? all,
            ),
        [value],
    );
    return getString;
};
