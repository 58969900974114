/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { Box, Flex } from "reflexbox";
import { ReactComponent as Arrow } from "../assets/arrow.svg";
import { Theme } from "../theme";
import styled from "../theme/styled";
import NumberFormat from "react-number-format";

const StyledArrow = styled(Arrow)`
    height: 50%;
    padding: 0 4px;
    color: ${p => p.theme.colors.textGrey};
    max-width: 20px;
    user-select: none;
    cursor: pointer;

    &:hover {
        background: ${p => p.theme.colors.light};
        border-radius: 5px;
    }
`;

type Props = {
    id: string;
    value: number | null;
    onSetValue: (id: string, value: number | null) => void;
    onSetTouched: (id: string, value: boolean) => void;
    error?: boolean;

    name: string;
    min?: number;
    max?: number;
    step?: number;

    noArrows?: boolean;
};

const NumberInput: React.FC<Props> = ({
    noArrows,
    id,
    value,
    name,
    onSetValue,
    onSetTouched,
    error,
    ...props
}) => {
    const [strValue, setStrValue] = React.useState<string>();
    React.useEffect(() => {
        setStrValue(value?.toLocaleString("cs-CZ"));
    }, [value]);
    return (
        <Box
            css={(theme: Theme) => css`
                position: relative;
                border: 1px solid
                    ${error ? theme.colors.error : theme.colors.grey};
                border-radius: 5px;
                background: white;
                flex-shrink: 0;

                @media (min-width: ${theme.breakpoints[0]}) {
                    &:hover > div {
                        display: flex;
                    }
                }
            `}
        >
            <NumberFormat
                id={id}
                value={strValue}
                name={name}
                thousandSeparator=" "
                onValueChange={val => {
                    setStrValue(val.formattedValue);
                    onSetValue(id, val.floatValue ?? null);
                    onSetTouched(id, true);
                }}
                {...props}
                css={(theme: Theme) => css`
                    appearance: none;
                    border: none;
                    background: none;
                    text-align: center;
                    width: 100px;
                    padding: ${theme.space[2]}px ${theme.space[3]}px;

                    /* Chrome, Safari, Edge, Opera */
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    /* Firefox */
                    -moz-appearance: textfield;

                    &:focus {
                        border-color: ${theme.colors.primary};
                    }
                `}
            />
            {!noArrows && (
                <Flex
                    flexDirection="column"
                    css={css`
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        display: none;
                    `}
                >
                    <StyledArrow
                        onClick={() => {
                            onSetTouched(id, true);
                            onSetValue(
                                id,
                                Math.min(
                                    (typeof value === "number" ? value : 0) +
                                        (props.step ?? 1),
                                    props.max ?? Infinity,
                                ),
                            );
                        }}
                    />
                    <StyledArrow
                        onClick={() => {
                            onSetTouched(id, true);
                            onSetValue(
                                id,
                                Math.max(
                                    (typeof value === "number" ? value : 0) -
                                        (props.step ?? 1),
                                    props.min ?? Infinity,
                                ),
                            );
                        }}
                        transform="rotate(180)"
                    />
                </Flex>
            )}
        </Box>
    );
};
export default NumberInput;
