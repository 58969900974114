import React, { useContext } from "react";

type Action = Partial<AppStateT>;

const AppReducer = (state: AppStateT, newState: Action): AppStateT => ({
    ...state,
    ...newState,
});

interface AppStateT {
    isUnset: boolean;
    managers: number;
    experiencedManagers: number;
    complexity: number;
    competition: number;
    salary: number;
    currency: "CZK" | "EUR" | "USD";
}

const initialState: AppStateT = {
    isUnset: true,
    managers: 1,
    experiencedManagers: 0,
    complexity: 1,
    competition: 1,
    salary: 45000,
    currency: "CZK",
};

const AppState = React.createContext<[AppStateT, React.Dispatch<Action>]>([
    initialState,
    undefined as any,
]);

export const useAppStateProvider = () => {
    const appState = React.useReducer(AppReducer, initialState);
    return {
        AppStateProvider: AppState.Provider,
        appState,
    };
};

export const useAppState = () => {
    const appState = useContext(AppState);
    return appState;
};
