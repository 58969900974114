import Color from "color";
import { space, SpaceProps } from "styled-system";
import styled from "../../theme/styled";

export const UnstyledButton = styled.button`
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
`;

const Button = styled.button<SpaceProps>`
    color: white;
    padding: ${p => p.theme.space[3]}px;
    background: ${p => p.theme.colors.primary};
    border: 0;
    border-radius: 5px;
    width: 180px;
    font-size: 16px;
    font-weight: bold;
    ${space}

    &:hover {
        background: ${p =>
            Color(p.theme.colors.primary)
                .darken(0.25)
                .string()};
    }
`;
export default Button;
