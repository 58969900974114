/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useCountUp } from "react-countup";
import { BoxProps, Flex } from "reflexbox";
import Typography from "./styled/Typography";

type Props = {
    value?: number;
    notAnimated?: boolean;
};
const Result: React.FC<Omit<BoxProps, "color"> & Props> = ({
    value,
    children,
    notAnimated,
    ...props
}) => {
    const roundedValue = Math.round(Math.max(value ?? 0, 0));
    const { countUp, update } = useCountUp({
        end: roundedValue,
        duration: 0.5,
        separator: " ",
    });
    React.useEffect(() => {
        !notAnimated && update(roundedValue);
    }, [update, roundedValue, notAnimated]);

    return (
        <Flex
            flexDirection={["row", "column"]}
            px={[4, 44]}
            py={3}
            alignItems="center"
            {...props}
        >
            <Typography
                textColor="primary"
                fontSize={46}
                fontWeight="bold"
                textAlign="center"
                css={css`
                    word-break: normal;
                `}
                my={0}
            >
                {value !== undefined
                    ? notAnimated
                        ? value.toLocaleString("cs")
                        : countUp
                    : "??"}
            </Typography>
            <Typography
                textStyle="small"
                textAlign={["left", "center"]}
                ml={[3, 0]}
                my={0}
                css={css`
                    min-width: 170px;
                    width: min-content;
                `}
            >
                {children}
            </Typography>
        </Flex>
    );
};
export default Result;
