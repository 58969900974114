/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { Box } from "reflexbox";
import { Theme } from "../theme";
import { ReactComponent as Dropdown } from "../assets/dropdown.svg";

type Props = {
    options: [string, string][];
} & React.HTMLProps<HTMLSelectElement>;

const SelectInput: React.FC<Props> = ({ options, ...props }) => {
    return (
        <Box
            css={(theme: Theme) => css`
                position: relative;
                border-radius: 5px;
                border: 1px solid ${theme.colors.grey};
                background: ${theme.colors.light};
            `}
        >
            <select
                {...props}
                css={(theme: Theme) => css`
                    appearance: none;
                    border: none;
                    background: none;
                    padding-left: 12px;
                    padding-right: ${theme.space[3]}px;
                    height: 100%;
                `}
            >
                {options.map(([value, label]) => (
                    <option key={value} value={value} label={label}>
                        {label}
                    </option>
                ))}
            </select>
            <Dropdown
                css={(theme: Theme) => css`
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    pointer-events: none;
                    color: ${theme.colors.textGrey};
                    width: ${theme.space[2]}px;
                    margin: 0 ${theme.space[1]}px;
                `}
            />
        </Box>
    );
};
export default SelectInput;
