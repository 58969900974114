import * as CSS from "csstype";
import {
    flexbox,
    FlexboxProps,
    ResponsiveValue,
    shadow,
    ShadowProps,
    space,
    SpaceProps,
    style,
    typography,
    TypographyProps,
    textStyle,
    TextStyleProps,
} from "styled-system";
import styled from "../../theme/styled";

// Idea: https://stackoverflow.com/a/55653275
const textColor = style({
    /* stylelint-disable-next-line property-no-unknown */
    prop: "textColor",
    /* stylelint-disable-next-line property-no-unknown */
    cssProperty: "color",
    /* stylelint-disable-next-line property-no-unknown */
    key: "colors",
});

interface ColorProp {
    textColor?: ResponsiveValue<CSS.ColorProperty>;
}

const Typography = styled.p<
    TypographyProps &
        SpaceProps &
        ColorProp &
        ShadowProps &
        FlexboxProps &
        TextStyleProps
>`
    ${typography}
    ${space}
    ${textColor}
    ${shadow}
    ${flexbox}
    ${textStyle}
`;

export default Typography;
