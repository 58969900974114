import styled from "../../theme/styled";
import { Flex } from "reflexbox";

const BorderGroup = styled(Flex)`
    & > *:first-child /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */ {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: 0;
    }
    & > *:last-child /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */ {
        border-left-width: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: 0;
    }
    & > *:not(:first-child):not(:last-child) /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */ {
        border-left-width: 0;
        border-radius: 0;
        margin-left: 0;
        margin-right: 0;
    }
`;
export default BorderGroup;
