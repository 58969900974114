import { ThemeProvider } from "emotion-theming";
import "pure-react-carousel/dist/react-carousel.es.css";
import React from "react";
import { useAppStateProvider } from "../state";
import { useLocalizationProvider } from "../state/Localization";
import { theme } from "../theme";
import Home from "./Home";

const App: React.FC = () => {
    const { AppStateProvider, appState } = useAppStateProvider();
    const { LocalizationProvider, localization } = useLocalizationProvider(
        process.env.REACT_APP_LANGUAGE as any,
    );

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider value={localization}>
                <AppStateProvider value={appState}>
                    <Home />
                </AppStateProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default App;
