/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useFormik } from "formik";
import { FC } from "react";
import { Flex } from "reflexbox";
import * as Yup from "yup";
import { useAppState } from "../state";
import { useLocalization } from "../state/Localization";
import styled from "../theme/styled";
import NumberInput from "./NumberInut";
import RangeInput from "./RangeInput";
import SelectInput from "./SelectInput";
import BorderGroup from "./styled/BorderGroup";
import Button from "./styled/Button";
import InputError from "./styled/InputError";
import StyledFlex from "./styled/StyledFlex";
import Typography from "./styled/Typography";

const RelativeFlex = styled(Flex)`
    position: relative;
`;

const Calculator: FC = () => {
    const getString = useLocalization();

    const [state, dispatch] = useAppState();

    const {
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        setFieldValue,
        setFieldTouched,
        touched,
    } = useFormik({
        initialValues: state,
        onSubmit: values => {
            dispatch({ ...values, isUnset: false });
            fetch(
                `https://webcentrum.muni.cz/redakce/api/CalculatorApi/Register?countManagers=${values.managers}&countExperienced=${values.experiencedManagers}&complexity=${values.complexity}&competition=${values.competition}&wage=${values.salary}`,
            );
        },
        validationSchema: Yup.object({
            managers: Yup.number()
                .min(1, getString("calc.managers.min"))
                .nullable()
                .required(getString("form.required")),
            experiencedManagers: Yup.number()
                .min(0, getString("calc.experiencedManagers.min"))
                .max(
                    Yup.ref("managers"),
                    getString("calc.experiencedManagers.fewer"),
                )
                .nullable()
                .required(getString("form.required")),
            salary: Yup.number()
                .min(0, getString("calc.salary.min"))
                .nullable()
                .required(getString("form.required")),
        }),
    });

    return (
        <StyledFlex
            rounded
            shadow
            fixedWidth
            backgroundColor="white"
            px={5}
            pt={4}
            mb={3}
        >
            <form onSubmit={handleSubmit}>
                <Flex flexDirection="column">
                    <RelativeFlex
                        justifyContent="space-between"
                        alignItems="center"
                        mb={4}
                    >
                        <Typography
                            {...{
                                as: "label",
                                htmlFor: "managers",
                            }}
                            flexGrow={1}
                            mr={2}
                        >
                            {getString("calc.managers.label")}
                        </Typography>
                        <NumberInput
                            step={1}
                            min={1}
                            id="managers"
                            name="managers"
                            value={values.managers}
                            onSetValue={setFieldValue}
                            onSetTouched={setFieldTouched}
                            error={!!touched.managers && !!errors.managers}
                        />
                        <Typography ml={3} flexShrink={0}>
                            {getString("form.people")}
                        </Typography>
                        {touched.managers && errors.managers && (
                            <InputError>{errors.managers}</InputError>
                        )}
                    </RelativeFlex>
                    <RelativeFlex
                        justifyContent="space-between"
                        alignItems="center"
                        mb={4}
                    >
                        <Typography
                            {...{
                                as: "label",
                                htmlFor: "experiencedManagers",
                            }}
                            flexGrow={1}
                            mr={2}
                        >
                            {getString("calc.experiencedManagers.label")}
                        </Typography>
                        <NumberInput
                            step={1}
                            min={0}
                            id="experiencedManagers"
                            name="experiencedManagers"
                            value={values.experiencedManagers}
                            onSetValue={setFieldValue}
                            onSetTouched={setFieldTouched}
                            error={
                                !!touched.experiencedManagers &&
                                !!errors.experiencedManagers
                            }
                        />
                        <Typography ml={3} flexShrink={0}>
                            {getString("form.people")}
                        </Typography>
                        {touched.experiencedManagers &&
                            errors.experiencedManagers && (
                            <InputError>
                                {errors.experiencedManagers}
                            </InputError>
                        )}
                    </RelativeFlex>
                    <RelativeFlex flexDirection="column" mb={4}>
                        <Typography mb={0}>
                            {getString("calc.complexity.label")}
                        </Typography>
                        <Typography textColor="textGrey" fontStyle="italic">
                            {getString("calc.complexity.sublabel")}
                        </Typography>
                        <RangeInput
                            id="complexity"
                            onSetValue={setFieldValue}
                            onSetTouched={setFieldTouched}
                            value={values.complexity}
                            labels={{
                                1: getString("form.simple"),
                                5: getString("form.complex"),
                            }}
                        />
                        {touched.complexity && errors.complexity && (
                            <InputError>{errors.complexity}</InputError>
                        )}
                    </RelativeFlex>
                    <RelativeFlex flexDirection="column" mb={4}>
                        <Typography mb={0}>
                            {getString("calc.competition.label")}
                        </Typography>
                        <Typography textColor="textGrey" fontStyle="italic">
                            {getString("calc.competition.sublabel")}
                        </Typography>
                        <RangeInput
                            id="competition"
                            onSetValue={setFieldValue}
                            onSetTouched={setFieldTouched}
                            value={values.competition}
                            labels={{
                                1: getString("form.low"),
                                5: getString("form.high"),
                            }}
                        />
                        {touched.competition && errors.competition && (
                            <InputError>{errors.competition}</InputError>
                        )}
                    </RelativeFlex>
                    <RelativeFlex
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            {...{
                                as: "label",
                                htmlFor: "salary",
                            }}
                            flexGrow={1}
                        >
                            {getString("calc.salary.label")}
                        </Typography>
                        <BorderGroup flexShrink={0}>
                            <NumberInput
                                min={0}
                                id="salary"
                                name="salary"
                                value={values.salary}
                                noArrows
                                css={css`
                                    width: 90px;
                                `}
                                onSetValue={setFieldValue}
                                onSetTouched={setFieldTouched}
                                error={!!touched.salary && !!errors.salary}
                            />
                            <SelectInput
                                id="currency"
                                name="currency"
                                onChange={e => {
                                    if (
                                        e.currentTarget.value !==
                                        values.currency
                                    ) {
                                        setFieldValue("salary", "");
                                    }
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.currency}
                                options={[
                                    ["CZK", getString("CZK")],
                                    ["EUR", getString("EUR")],
                                    ["USD", getString("USD")],
                                ]}
                            />
                        </BorderGroup>
                        {touched.salary && errors.salary && (
                            <InputError>{errors.salary}</InputError>
                        )}
                    </RelativeFlex>
                    <Button
                        type="submit"
                        css={css`
                            transform: translateY(50%);
                            align-self: center;
                        `}
                    >
                        {getString("calc.calculate")}
                    </Button>
                </Flex>
            </form>
        </StyledFlex>
    );
};
export default Calculator;
