import styled from "../../theme/styled";
import Typography from "./Typography";

const InputError = styled(Typography)`
    position: absolute;
    right: 0;
    bottom: 0;
    color: ${p => p.theme.colors.error};
    margin: 0;
    transform: translateY(100%);
`;
export default InputError;
