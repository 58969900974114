import { css } from "@emotion/core";
import { Flex } from "reflexbox";
import styled from "../../theme/styled";

const StyledFlex = styled(Flex)<{
    shadow?: boolean;
    rounded?: boolean;
    fixedWidth?: boolean;
    bodyWidth?: boolean;
}>`
    ${p =>
        p.rounded &&
        css`
            border-radius: 15px;
        `}
    ${p =>
        p.shadow &&
        css`
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
        `}
    ${p =>
        p.fixedWidth &&
        css`
            align-self: center;
            max-width: ${p.theme.breakpoints[0]};
        `}

    ${p =>
        p.bodyWidth &&
        css`
            padding-left: 15px;
            padding-right: 15px;
            max-width: ${p.theme.breakpoints[1]};
            margin: auto;

            @media (min-width: ${p.theme.breakpoints[1]}) {
                padding-left: 35px;
                padding-right: 35px;
            }

            @media (min-width: ${p.theme.breakpoints[0]}) {
                padding-left: 25px;
                padding-right: 25px;
            }
        `}
    
`;
export default StyledFlex;
