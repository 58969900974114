export const TEXT_COLOR = "#000000";

export const theme = {
    colors: {
        primary: "#0000dc",
        light: "#d6e6ff",
        grey: "#a3a3a3",
        bg: "#f1f1f1",
        bgDark: "#3f4a5a",
        text: TEXT_COLOR,
        textGrey: "#666666",
        error: "red",
    },
    textStyles: {
        small: {
            fontSize: "14px",
        },
        normal: {
            fontSize: "16px",
        },
        heading1: {
            fontSize: "38px",
            fontWeight: "bold",
        },
        heading2: {
            fontSize: "32px",
        },
    },
    breakpoints: ["768px", "1024px"],
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    bodyPadding: ["15px", "25px", "35px"],
};

export type Theme = typeof theme;
